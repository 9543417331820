<template>
  <EntityList
    :config="config"
    :columns="columns"
    single-edit
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    authority="Users_Participant"
    desc-sort
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../PersonStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
      specialtiesOptions: [],
      professionOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'participants',
        endpoint: 'participants',
        route: 'persons',
        title: {
          single: this.$t('Person Add'),
          plural: this.$t('Persons'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'firstName', label: this.$t('First Name'), sortable: true },
        { key: 'lastName', label: this.$t('Last Name'), sortable: true },
        { key: 'specialty.title', label: this.$t('Specialty'), sortable: false },
        { key: 'country.title', label: this.$t('Country'), sortable: false },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'startDate', type: 'date' },
        { key: 'endDate', type: 'date' },
      ]
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('Country'),
          options: this.countryOptions,
          colSize: 3,
        },
        {
          name: 'specialty',
          label: this.$t('Specialty'),
          options: this.specialtiesOptions,
          colSize: 3,
        },
        {
          name: 'profession',
          label: this.$t('Profession'),
          options: this.professionOptions,
          colSize: 3,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSpecialties').then(response => {
      this.specialtiesOptions = response.data
    })
    this.$store.dispatch('app-common/fetchProfessions').then(response => {
      this.professionOptions = response.data
    })
  },
}
</script>
